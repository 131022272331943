import React, { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  MenuItem,
  Button,
  Tooltip,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Avatar,
  TextField,
  CircularProgress
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import { cupomDescontoAction, eventoAction, loteAction } from '_actions'
import { HelperToolTip, ValidatedDatePicker } from 'components/'
import { toast } from 'react-toastify'
import {
  cepMask,
  cpfCnpjMask,
  getInitials,
  phoneMask,
  toISODateFormat,
  toLocalDateFormat,
  useDebouncedEffect,
  useDidMountEffect,
  validateDates
} from 'utils'
import { history } from 'utils'
import { format, isValid } from 'date-fns'
import { Page } from 'components'
import { makeStyles } from '@material-ui/styles'
import { useParams } from 'react-router-dom'
import constants from 'theme/constants'
import {
  useFormaPagamentoSelect,
  useMemberById,
  useMembershipPlanId,
  useMembershipPlanList,
  useMutationMemberEdit,
  useMutationMembershipPlanCreate,
  useMutationMembershipPlanEdit,
  useUserInfo,
  useUserSearch
} from 'hooks-querys'
import {
  FormaPagamento,
  Member,
  MembershipPlan,
  User,
  UserItem
} from 'types/api'
import { Autocomplete } from '@material-ui/lab'
import { useCityInfo } from 'hooks-querys/city'
import { isEmpty } from 'lodash'
import {
  useCountryStateInfo,
  useCountryStates
} from 'hooks-querys/countryState'
import useCities from 'hooks-querys/city/useCities'
import useCitiesByStateId from 'hooks-querys/city/useCitiesByStateId'
import { UploadField } from 'components/Utils/Uploads'
import useMutationMemberCreate from 'hooks-querys/members/useMutationMemberCreate'

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2)
  },
  paper: {
    ...constants.shadowCard
  },
  formControl: {
    width: '100%'
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 40,
    width: 40,
    // @ts-ignore
    marginRight: theme.spacing(1)
  }
}))

type MemberForm = {
  gender: 'M' | 'F' | 'U'
}

// @ts-ignore
const MemberEdit = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  // @ts-ignore
  const { params, membershipPlanId } = useParams()
  const memberId = membershipPlanId
  const { data: memberEdit } = useMemberById(Number(memberId))
  const [selectedUser, setSelectedUser] = useState<UserItem[]>([] as UserItem[])
  const [searchTextValue, setSearchTextValue] = useState('')
  const [searchTextValueToSubmit, setSearchTextValueToSubmit] = useState('')
  const [name, setName] = useState<string | null>()
  const [email, setEmail] = useState<string | null>()
  const [cpfCnpj, setCpfCnpj] = useState<string | null>()
  const [image, setImage] = useState<string | null>()
  const [phone, setPhone] = useState<string | null>()
  const [birthDate, setBirthDate] = useState<Date | string | null>(null)
  const [cep, setCep] = useState<string | null>()
  const [bairro, setBairro] = useState<string | null>()
  const [cityId, setCityId] = useState<number | null>(null)
  const [stateId, setStateId] = useState<number | null>(null)
  const [complemento, setComplemento] = useState<string | null>()
  const [address, setAddress] = useState<string | null>()
  const [numberAddress, setNumberAddress] = useState<string | null>()
  const [gender, setGender] = useState<'M' | 'F' | 'U' | null>()
  const [matricula, setMatricula] = useState<string | null>(null)
  const [planId, setPlanId] = useState<number | null>(null)
  const [planPriceId, setPlanPriceId] = useState<number | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [paymentMethod, setPaymentMethod] = useState<FormaPagamento | null>(
    null
  )
  const [initialDatePayment, setInitialDatePayment] = useState<Date | null>(
    null
  )

  const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const mutationMemberCreate = useMutationMemberCreate()
  const mutationEditMember = useMutationMemberEdit()

  const LoadEditMemberDetails = () => {
    if (!!memberEdit) {
      setName(memberEdit.membro.nome)
      setImage(memberEdit.membro.imagem)
      setEmail(memberEdit.membro.email)
      setCpfCnpj(memberEdit.membro.cpfCnpj)
      setImage(memberEdit.membro.imagem)
      setPhone(memberEdit.membro.telefone)
      setBirthDate(memberEdit.membro.dataNascimento)
      setCep(memberEdit.membro.cep)
      setBairro(memberEdit.membro.bairro)
      setCityId(memberEdit.membro.cidadeId)
      // setState(cityInfoForEdit?.estadoId)
      setComplemento(memberEdit.membro.complemento)
      setAddress(memberEdit.membro.endereco)
      setNumberAddress(memberEdit.membro.numero)
      setGender(memberEdit.membro.genero)
      setSelectedUser(memberEdit?.usuario ? [memberEdit?.usuario] : [])
      // setSelectedUser(userEditInfo)
      // setPlanId(memberEdit.planoId)
      // setPlanPriceId(memberEdit.planoValorId)
      // setPaymentMethod(memberEdit.formaPagamentoId)
      // setMatricula(memberEdit.matricula)
      // memberEdit.statusMembroPlanoId)
      // setInitialDatePayment(memberEdit.dataInicioCobranca)
      // setStartDate(memberEdit.dataHoraInicio)
      // setEndDate(memberEdit.dataHoraFim)
    }
  }

  useEffect(() => {
    LoadEditMemberDetails()
  }, [memberEdit])

  const { isLoading: isLoadingPlans, data: plans } = useMembershipPlanList(
    estabelecimentoId
  )

  const {
    isLoading: isLoadingPlanPrice,
    data: membershipPlan
  } = useMembershipPlanId(Number(planId))

  const {
    data: formaPagamentoSelect,
    isLoading: isLoadingFormaPagamentoSelect
  } = useFormaPagamentoSelect()

  const {
    data: resultUserSearch,
    isError: isErrorUserSearch,
    isLoading: isLoadingUserSearch
  } = useUserSearch(searchTextValueToSubmit)

  const { data: userInfo } = useUserInfo(selectedUser[0]?.id)

  // const {
  //   data: userEditInfo
  // } = useUserInfo(memberEdit.usuarioId)

  const { data: cityInfo } = useCityInfo(
    userInfo?.cidadeId ? userInfo.cidadeId : 0
  )

  const { data: cityInfoForEdit } = useCityInfo(cityId ? cityId : 0)

  useDidMountEffect(() => {
    cityInfoForEdit?.estadoId && setStateId(cityInfoForEdit?.estadoId)
  }, [cityInfoForEdit])

  const { data: countryStateInfo } = useCountryStateInfo(
    cityInfo?.estadoId ? cityInfo?.estadoId : 0
  )

  const { data: states } = useCountryStates()

  const { data: citiesByStateId } = useCitiesByStateId(
    cityInfo?.estadoId ? cityInfo?.estadoId : stateId ? stateId : 0,
    stateId
  )

  const { data: cities } = useCities()

  useDebouncedEffect(
    () => {
      setSearchTextValueToSubmit(searchTextValue)
    },
    500,
    [searchTextValue]
  )

  const handleAlreadyUser = () => {
    if (!!userInfo && !memberId) {
      userInfo.nome && setName(userInfo.nome)
      userInfo.email && setEmail(userInfo.email)
      userInfo.cpfCnpj && setCpfCnpj(userInfo.cpfCnpj)
      userInfo.imagem && setImage(userInfo.imagem)
      userInfo.telefone && setPhone(userInfo.telefone)
      userInfo.dataNascimento && setBirthDate(userInfo.dataNascimento)
      userInfo.cep && setCep(userInfo.cep)
      userInfo.bairro && setBairro(userInfo.bairro)
      userInfo.cidadeId && setCityId(userInfo.cidadeId)
      userInfo.cidade?.estadoId && setStateId(userInfo.cidade?.estadoId)
      userInfo.complemento && setComplemento(userInfo.complemento)
      userInfo.endereco && setAddress(userInfo.endereco)
      userInfo.numero && setNumberAddress(userInfo.numero)
      userInfo.genero && setGender(userInfo.genero)
    } else if (!memberId) {
      setName(null)
      setEmail(null)
      setCpfCnpj(null)
      setImage(null)
      setPhone(null)
      setBirthDate(null)
      setCep(null)
      setBairro(null)
      setCityId(null)
      setStateId(null)
      setComplemento(null)
      setAddress(null)
      setNumberAddress(null)
      setGender(null)
    }
  }

  useDidMountEffect(() => {
    handleAlreadyUser()
  }, [userInfo])

  const cancelClick = () => {
    history.goBack()
  }

  const handleSubmit = () => {
    if (memberEdit) {
      handleEdit()
    } else {
      // if (!!planId && !!planPriceId  && !!startDate && !!paymentMethod && !!birthDate) {
      if (!!planId && !!planPriceId && !!startDate && !!paymentMethod) {
        let membroPlano = [
          {
            // id: null,
            // membroId: 0,
            planoId: planId,
            planoValorId: planPriceId,
            // dataHoraInicio: !!startDate ? format(new Date(startDate), 'dd/MM/yyyy HH:mm') : null,
            // dataHoraFim: !!endDate ? format(new Date(endDate), 'dd/MM/yyyy HH:mm') : null,
            dataHoraInicio: startDate,
            dataHoraFim: endDate,
            formaPagamentoId: Number(paymentMethod),
            matricula: matricula,
            statusMembroPlanoId: 1,
            dataInicioCobranca: initialDatePayment
          }
        ]

        let payload = {
          id: 0,
          nome: name,
          email: email,
          cpfCnpj: !!cpfCnpj
            ? cpfCnpj.split('.').join('').replace(/[-/.]/g, '')
            : null,
          imagem: image,
          telefone: !!phone ? phone.replace(/[^0-9]/g, '') : null,
          // qrcode
          // dataNascimento: format(Number(birthDate), 'yyyy-MM-dd'),
          dataNascimento: birthDate,
          usuarioId: userInfo?.id,
          cidadeId: cityId,
          genero: gender,
          endereco: address,
          bairro: bairro,
          numero: numberAddress,
          complemento: complemento,
          cep: !!cep ? cep.replace(/[^0-9]/g, '') : null,
          membroPlano: membroPlano
        }

        // @ts-ignore
        createMember(payload)
        console.error('payload', payload)
      } else {
        // toast.error('Informe a data de nascimento, um plano, um valor, data de início e forma de pagamento!')
        toast.error(
          'Informe um plano, um valor, data de início e forma de pagamento!'
        )
      }
    }
  }

  const createMember = (payload: Member) => {
    mutationMemberCreate.mutateAsync(payload).then(() => {
      history.push('/members/listaMembro')
      // dispatch(albumAction.deleteAllFoto())
    })
  }

  const handleEdit = () => {
    let payload = {
      id: memberId,
      nome: name,
      email: email,
      cpfCnpj: !!cpfCnpj
        ? cpfCnpj.split('.').join('').replace(/[-/.]/g, '')
        : null,
      imagem: image,
      telefone: !!phone ? phone.replace(/[^0-9]/g, '') : null,
      dataNascimento: birthDate,
      usuarioId: selectedUser[0]?.id,
      cidadeId: cityId,
      genero: gender,
      endereco: address,
      bairro: bairro,
      numero: numberAddress,
      complemento: complemento,
      cep: !!cep ? cep.replace(/[^0-9]/g, '') : null
      // membroPlano: membroPlano
    }

    // @ts-ignore
    editMemberPlan(payload)
  }

  const editMemberPlan = (payload: Member) => {
    mutationEditMember.mutateAsync(payload).then(() => {
      history.push('/members/listaMembro')
    })
  }

  const handleOnChangeState = (event: any) => {
    setStateId(Number(event.target.value))
    setCityId(null)
  }

  return (
    <Page
      title={
        memberId
          ? 'Gerência Plano - Editar Membro'
          : 'Gerência Plano - Novo Membro'
      }
    >
      <Grid item md={12} xs={12}>
        <Card className={classes.paper}>
          <form>
            <CardHeader
              subheader={
                memberId
                  ? 'Para a edição de membro apenas alguns campos podem ser modificados. Para alterar email, plano, valor/periodicidade do plano, entre outros, deve-se excluir o membro e cadastrar novamente com as modificações desejadas.'
                  : 'Cadastro Membro'
              }
              title={memberId ? 'Editar Membro' : 'Novo Membro'}
            />
            <ValidatorForm id="formMemberEdit" onSubmit={handleSubmit}>
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <Autocomplete
                        getOptionDisabled={(option) =>
                          selectedUser.some((user) => user.id === option?.id)
                        }
                        loading={isLoadingUserSearch}
                        id="combo-box-user"
                        multiple
                        options={
                          resultUserSearch?.filter(
                            (userItem) => userItem.tipo === 'usuario'
                          ) || []
                        }
                        value={selectedUser}
                        onChange={(event, newValue, reason) => {
                          if (reason === 'select-option') {
                            newValue !== null &&
                              setSelectedUser([newValue[newValue.length - 1]])
                            handleAlreadyUser()
                          }
                          if (reason === 'clear') {
                            //? se o usuário clicar no botão limpar
                            setSearchTextValue('')
                            setSelectedUser([] as UserItem[])
                          }
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            if (index === value.length - 1) {
                              return (
                                <Chip
                                  {...getTagProps({ index })}
                                  avatar={
                                    <Avatar src={option?.imagem}>
                                      {getInitials(option?.nome)}
                                    </Avatar>
                                  }
                                  variant="outlined"
                                  label={`${option?.nome} ${
                                    option?.username
                                      ? `(@${option?.username})`
                                      : ''
                                  }`}
                                  onDelete={() => {
                                    setSearchTextValue('')
                                    setSelectedUser([] as UserItem[])
                                  }}
                                />
                              )
                            }
                          })
                        }
                        getOptionLabel={(userItem) =>
                          userItem.nome &&
                          `${userItem.nome} ${
                            userItem.username ? ` - (${userItem.username})` : ''
                          }`
                        }
                        placeholder="Buscar por nome ou nome do usuário..."
                        onInputChange={(event, newInputValue) => {
                          newInputValue !== null
                            ? setSearchTextValue(newInputValue)
                            : setSearchTextValue('')
                        }}
                        inputValue={searchTextValue}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Se membro já possuir cadastro no Corujas, selecione-o aqui"
                            placeholder="Buscar por nome ou nome do usuário..."
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  <InputAdornment position="end">
                                    <>
                                      {isLoadingUserSearch && (
                                        <CircularProgress
                                          style={{ marginRight: 10 }}
                                          size={20}
                                          color="primary"
                                        />
                                      )}
                                    </>
                                  </InputAdornment>
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                        noOptionsText={
                          searchTextValueToSubmit.length > 0
                            ? 'Nenhum usuário encontrado'
                            : 'Digite para buscar'
                        }
                        renderOption={(userItem) => (
                          <div className={classes.nameCell}>
                            <Avatar
                              className={classes.avatar}
                              src={userItem?.imagem}
                            >
                              {getInitials(userItem?.nome)}
                            </Avatar>
                            <div>
                              <Typography variant="body1">
                                {`${userItem?.nome} ${
                                  userItem.username
                                    ? `(@${userItem.username})`
                                    : ''
                                }`}
                              </Typography>
                            </div>
                          </div>
                        )}
                        //disabled={memberId}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextValidator
                      name="name"
                      variant="outlined"
                      id="nome"
                      label="Nome *"
                      value={name || ''}
                      onChange={(event) => {
                        // @ts-ignore
                        setName(event.target.value)
                      }}
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      fullWidth
                      multiline
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <>
                                {!!name && name.length > 200 ? (
                                  <Typography
                                    variant="body2"
                                    align="right"
                                    color="error"
                                  >
                                    {name?.length}/200
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" align="right">
                                    {name?.length}/200
                                  </Typography>
                                )}
                              </>
                            </InputAdornment>
                          </>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextValidator
                      name="email"
                      variant="outlined"
                      id="email"
                      label="Email *"
                      value={email || ''}
                      onChange={(event) => {
                        // @ts-ignore
                        setEmail(event.target.value)
                      }}
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      fullWidth
                      disabled={memberId}
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <>
                                {!!email && email.length > 100 ? (
                                  <Typography
                                    variant="body2"
                                    align="right"
                                    color="error"
                                  >
                                    {email?.length}/100
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" align="right">
                                    {email?.length}/100
                                  </Typography>
                                )}
                              </>
                            </InputAdornment>
                          </>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextValidator
                      name="cpfCnpj"
                      variant="outlined"
                      id="cpfCnpj"
                      label="CPF/CNPJ *"
                      value={cpfCnpjMask(cpfCnpj) || ''}
                      onChange={(event) =>
                        // @ts-ignore
                        setCpfCnpj(event.target.value)
                      }
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextValidator
                      name="phone"
                      variant="outlined"
                      id="phone"
                      label="Telefone *"
                      value={phoneMask(phone) || ''}
                      onChange={(event) =>
                        // @ts-ignore
                        setPhone(event.target.value)
                      }
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <ValidatedDatePicker
                      name="birthDate"
                      // validators={['isValidDate']}
                      // errorMessages={['Data inválida!']}
                      autoOk
                      id="birthDate"
                      label="Data Nascimento"
                      fullWidth
                      animateYearScrolling
                      inputVariant="outlined"
                      ampm={false}
                      value={birthDate}
                      //inputValue={startDate}
                      // @ts-ignore
                      onChange={(newValue) => setBirthDate(newValue)}
                      //disablePast
                      format="dd/MM/yyyy"
                      // invalidDateMessage="Data inválida!"
                      // invalidLabel="dd/MM/aaaa HH:mm"
                      // maxDateMessage="A data não deve ser posterior à data máxima"
                      //minDateMessage="A data não deve ser anterior à data de hoje"
                      cancelLabel="Cancelar"
                      okLabel="Definir"
                      clearable
                      clearLabel="Limpar"
                      placeholder="dd/MM/aaaa"
                      todayLabel="Hoje"
                      defaultValue={undefined}
                      openTo="month"
                      views={['month', 'date']}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextValidator
                      name="cep"
                      variant="outlined"
                      id="cep"
                      label="CEP *"
                      value={cepMask(cep) || ''}
                      onChange={(event) =>
                        // @ts-ignore
                        setCep(event.target.value)
                      }
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <SelectValidator
                      name="countryState"
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      variant="outlined"
                      // disabled={estabelecimento.isSearchingCep}
                      id="estadoId"
                      label="Estado *"
                      value={cityInfo ? cityInfo.estadoId : stateId}
                      onChange={(event) => {
                        // @ts-ignore
                        handleOnChangeState(event)
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      displayEmpty
                      labelId="estadoId"
                    >
                      <MenuItem disabled value="">
                        <em>Estado *</em>
                      </MenuItem>
                      {!isEmpty(states) &&
                        undefined !== states &&
                        states.length &&
                        // @ts-ignore
                        states.map((row) => (
                          <MenuItem key={row.id} value={row.id}>
                            {row.nome}
                          </MenuItem>
                        ))}
                    </SelectValidator>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <SelectValidator
                      name="cityId"
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      disabled={!stateId && !userInfo}
                      variant="outlined"
                      id="cidadeId"
                      label="Cidade *"
                      value={cityId || ''}
                      onChange={(event) => {
                        // @ts-ignore
                        setCityId(Number(event.target.value))
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      displayEmpty
                      labelId="cidadeId"
                    >
                      <MenuItem disabled value="">
                        <em>Cidade *</em>
                      </MenuItem>
                      {
                        !isEmpty(citiesByStateId) &&
                          undefined !== citiesByStateId &&
                          citiesByStateId.length &&
                          citiesByStateId.map((row) => (
                            <MenuItem key={row.id} value={row.id}>
                              {row.nome}
                            </MenuItem>
                          ))
                        // : !isEmpty(cities) &&
                        // undefined !== cities &&
                        // cities.length &&
                        // cities.map((row) => (
                        //   <MenuItem key={row.id} value={row.id}>
                        //     {row.nome}
                        //   </MenuItem>
                        // ))
                      }
                    </SelectValidator>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextValidator
                      name="address"
                      variant="outlined"
                      id="address"
                      label="Endereço *"
                      value={address || ''}
                      onChange={(event) =>
                        // @ts-ignore
                        setAddress(event.target.value)
                      }
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <>
                                {!!address && address.length > 200 ? (
                                  <Typography
                                    variant="body2"
                                    align="right"
                                    color="error"
                                  >
                                    {address?.length}/200
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" align="right">
                                    {address?.length}/200
                                  </Typography>
                                )}
                              </>
                            </InputAdornment>
                          </>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextValidator
                      name="numberAddress"
                      variant="outlined"
                      id="numberAddress"
                      label="Número *"
                      value={numberAddress || ''}
                      onChange={(event) =>
                        // @ts-ignore
                        setNumberAddress(event.target.value)
                      }
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <>
                                {!!numberAddress &&
                                numberAddress.length > 50 ? (
                                  <Typography
                                    variant="body2"
                                    align="right"
                                    color="error"
                                  >
                                    {numberAddress?.length}/50
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" align="right">
                                    {numberAddress?.length}/50
                                  </Typography>
                                )}
                              </>
                            </InputAdornment>
                          </>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextValidator
                      name="complemento"
                      variant="outlined"
                      id="complemento"
                      label="Complemento "
                      value={complemento || ''}
                      onChange={(event) =>
                        // @ts-ignore
                        setComplemento(event.target.value)
                      }
                      // validators={['required']}
                      // errorMessages={['Campo obrigatório!']}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <>
                                {!!complemento && complemento.length > 200 ? (
                                  <Typography
                                    variant="body2"
                                    align="right"
                                    color="error"
                                  >
                                    {complemento?.length}/200
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" align="right">
                                    {complemento?.length}/200
                                  </Typography>
                                )}
                              </>
                            </InputAdornment>
                          </>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextValidator
                      name="bairro"
                      variant="outlined"
                      id="bairro"
                      label="Bairro *"
                      value={bairro || ''}
                      onChange={(event) =>
                        // @ts-ignore
                        setBairro(event.target.value)
                      }
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <>
                                {!!bairro && bairro.length > 100 ? (
                                  <Typography
                                    variant="body2"
                                    align="right"
                                    color="error"
                                  >
                                    {bairro?.length}/100
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" align="right">
                                    {bairro?.length}/100
                                  </Typography>
                                )}
                              </>
                            </InputAdornment>
                          </>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <SelectValidator
                      name="gender"
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      variant="outlined"
                      id="gender"
                      label="Gênero *"
                      value={gender || ''}
                      onChange={(event) =>
                        // @ts-ignore
                        setGender(event.target.value)
                      }
                      fullWidth
                      displayEmpty
                      labelId="gender"
                    >
                      <MenuItem disabled value="">
                        <em>Gênero</em>
                      </MenuItem>
                      <MenuItem value="M">Masculino</MenuItem>
                      <MenuItem value="F">Feminino</MenuItem>
                    </SelectValidator>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextValidator
                      name="matricula"
                      variant="outlined"
                      id="matricula"
                      label="Matricula"
                      value={matricula}
                      onChange={(event) =>
                        // @ts-ignore
                        setMatricula(event.target.value)
                      }
                      fullWidth
                      disabled={memberId}
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <>
                                {!!matricula && matricula.length > 20 ? (
                                  <Typography
                                    variant="body2"
                                    align="right"
                                    color="error"
                                  >
                                    {matricula?.length}/20
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" align="right">
                                    {matricula?.length}/20
                                  </Typography>
                                )}
                              </>
                            </InputAdornment>
                          </>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <ValidatedDatePicker
                      name="initialDatePayment"
                      autoOk
                      id="initialDatePayment"
                      label="Data de Início da Cobrança"
                      fullWidth
                      animateYearScrolling
                      inputVariant="outlined"
                      ampm={false}
                      value={initialDatePayment}
                      //inputValue={startDate}
                      // @ts-ignore
                      onChange={(newValue) => setInitialDatePayment(newValue)}
                      disablePast
                      format="dd/MM/yyyy"
                      // invalidDateMessage="Data inválida!"
                      // invalidLabel="dd/MM/aaaa HH:mm"
                      // maxDateMessage="A data não deve ser posterior à data máxima"
                      minDateMessage="A data não deve ser anterior à data de hoje"
                      cancelLabel="Cancelar"
                      okLabel="Definir"
                      clearable
                      clearLabel="Limpar"
                      placeholder="dd/MM/aaaa"
                      todayLabel="Hoje"
                      defaultValue={undefined}
                      openTo="month"
                      views={['month', 'date']}
                      disabled={memberId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="plano-label">
                        Selecione o plano *
                      </InputLabel>
                      <Select
                        name="plano-select"
                        disabled={isLoadingPlans || memberId}
                        placeholder="Selecione o plano *"
                        labelId="plano-label"
                        id="plano-select"
                        value={planId}
                        onChange={(event) => {
                          // @ts-ignore
                          setPlanId(Number(event.target.value))
                        }}
                        label="Selecione o plano *"
                      >
                        <MenuItem disabled={planId === 0} key={0} value={0}>
                          {planId === 0 ? (
                            'Selecione o plano *'
                          ) : (
                            <strong>Limpar seleção</strong>
                          )}
                        </MenuItem>
                        {plans &&
                          plans.length > 0 &&
                          // @ts-ignore
                          plans.map((plan) => (
                            <MenuItem
                              disabled={plan.id === planId}
                              key={plan.id}
                              value={plan.id}
                            >
                              {plan.nome}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {planId != null && planId > 0 && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="planPrice-label">
                            Selecione o valor *
                          </InputLabel>
                          <Select
                            disabled={isLoadingPlanPrice}
                            placeholder="Selecione o valor *"
                            labelId="planPrice-label"
                            id="planPrice-select"
                            value={planPriceId}
                            onChange={(event) => {
                              setPlanPriceId(Number(event.target.value))
                            }}
                            label="Selecione o valor *"
                          >
                            <MenuItem
                              disabled={planPriceId === 0}
                              key={0}
                              value={0}
                            >
                              {planPriceId === 0 ? (
                                'Selecione o valor *'
                              ) : (
                                <strong>Limpar seleção</strong>
                              )}
                            </MenuItem>
                            {membershipPlan?.planoValor &&
                              membershipPlan?.planoValor?.length > 0 &&
                              // @ts-ignore
                              membershipPlan?.planoValor?.map((planPrice) => (
                                <MenuItem
                                  disabled={planPrice.id === planPriceId}
                                  key={planPrice.id}
                                  value={planPrice.id}
                                >
                                  {planPrice.periodicidade === 'M'
                                    ? `R$ ${planPrice.valor} mensal`
                                    : `R$ ${planPrice.valor} anual`}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <ValidatedDatePicker
                          name="dataHoraInicio"
                          // validators={['isValidDate']}
                          // errorMessages={['Data inválida!']}
                          autoOk
                          id="dataHoraInicio"
                          label="Data/Hora Início Associação*"
                          fullWidth
                          animateYearScrolling
                          inputVariant="outlined"
                          ampm={false}
                          value={startDate}
                          //inputValue={startDate}
                          // @ts-ignore
                          onChange={(newValue) => setStartDate(newValue)}
                          //disablePast
                          format="dd/MM/yyyy HH:mm"
                          invalidDateMessage="Data inválida!"
                          invalidLabel="dd/MM/aaaa HH:mm"
                          maxDateMessage="A data não deve ser posterior à data máxima"
                          //minDateMessage="A data não deve ser anterior à data de hoje"
                          cancelLabel="Cancelar"
                          okLabel="Definir"
                          clearable
                          clearLabel="Limpar"
                          placeholder="dd/MM/aaaa HH:mm"
                          todayLabel="Hoje"
                          defaultValue={undefined}
                          openTo="month"
                          views={['month', 'date', 'hours', 'minutes']}
                          disabled={memberId}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <ValidatedDatePicker
                          name="dataHoraFim"
                          // validators={['isValidDate']}
                          // errorMessages={['Data inválida!']}
                          autoOk
                          id="dataHoraFim"
                          label="Data/Hora Fim Associação"
                          animateYearScrolling
                          fullWidth
                          inputVariant="outlined"
                          ampm={false}
                          value={endDate}
                          // inputValue={endDate}
                          // @ts-ignore
                          onChange={(newValue) => setEndDate(newValue)}
                          disablePast
                          minDate={startDate}
                          format="dd/MM/yyyy HH:mm"
                          invalidDateMessage="Data inválida!"
                          invalidLabel="dd/MM/aaaa HH:mm"
                          maxDateMessage="A data não deve ser posterior à data máxima"
                          minDateMessage="A data não deve ser anterior à data de início"
                          cancelLabel="Cancelar"
                          okLabel="Definir"
                          clearable
                          clearLabel="Limpar"
                          placeholder="dd/MM/aaaa HH:mm"
                          todayLabel="Hoje"
                          defaultValue={undefined}
                          openTo="month"
                          views={['month', 'date', 'hours', 'minutes']}
                          disabled={memberId}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="forma-pagamento-label">
                            Forma de Pagamento *
                          </InputLabel>
                          <Select
                            disabled={isLoadingFormaPagamentoSelect || memberId}
                            placeholder="Forma de Pagamento *"
                            labelId="forma-pagamento-label"
                            id="forma-pagamento-select"
                            value={paymentMethod}
                            onChange={(event) => {
                              // @ts-ignore
                              setPaymentMethod(event.target.value)
                            }}
                            label="Forma de pagamento"
                          >
                            <MenuItem disabled value="">
                              <em>Forma de pagamento</em>
                            </MenuItem>
                            <MenuItem value="2">Boleto</MenuItem>
                            {/* {formaPagamentoSelect &&
                          formaPagamentoSelect.length > 0 &&
                          formaPagamentoSelect.map((formaPagamento) => (
                            <MenuItem
                              key={formaPagamento.value}
                              value={formaPagamento.value}
                            >
                              {formaPagamento.text}
                            </MenuItem>
                          ))} */}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <UploadField
                      parentCallback={setImage}
                      url={image}
                      labelText={'Foto'}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  onClick={() => cancelClick()}
                  color="secondary"
                  variant="outlined"
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  style={{ width: 100, height: 40 }}
                  disabled={
                    mutationEditMember.isLoading ||
                    mutationMemberCreate.isLoading
                  }
                >
                  {mutationEditMember.isLoading ||
                  mutationMemberCreate.isLoading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : memberId ? (
                    'Atualizar'
                  ) : (
                    'Salvar'
                  )}
                </Button>
              </CardActions>
            </ValidatorForm>
          </form>
        </Card>
      </Grid>
    </Page>
  )
}

export default MemberEdit

